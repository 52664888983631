::-webkit-scrollbar {
    // height: 4px;              /* height of horizontal scrollbar ← Youre missing this */
    // width: 4px;               /* width of vertical scrollbar */
    // border: 1px solid #F697E0;
    background-color: #F697E0;
    border-style: none;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 50px;
    background: #FCDDF5;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #F697E0;
  }

.photo {
    align-self: center;
    // border-radius: 50%;
    border-style: none;
    // height: 334px;
    max-width: 350px;
    max-height: 300px;
    overflow: hidden;
}

.intro {
    margin-top: 15px;
    max-width: 350px;
}

.largePhoto {
    max-width: 650px;
    max-height: 650px;
}

.largePhotoBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 5px solid #F697E0;
    border-radius: 10px;
    box-shadow: 24;
    padding: 4;
    max-width: 600;
    max-height: 600;
    margin: 4;
}

.largePhotoModal {
    max-width: 650;
    max-height: 650;
    overflow: auto;
    
}

// MOBILE
@media screen AND (max-width: 479px) {
    .portfolioContainer {
        background-color: #fff;
        border-radius: 15px;
        border-style: none;
        // margin-top: 0;
        max-width: 353px !important;
        padding: 15px !important;
        width: 79%;
        margin: 15px;
    }

    .largePhotoBox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border: 5px solid #F697E0;
        border-radius: 10px;
        box-shadow: 24;
        padding: 4;
        max-width: 400;
        max-height: 400;
        margin: 4;
    }
}

// TABLET
@media screen AND (min-width: 480px) AND (max-width: 767px) {
    .portfolioContainer {
        background-color: #fff;
        border-radius: 15px;
        border-style: none;
        // margin-top: 15px;
        // width: 58%;
        width: 85% !important;
        max-width: 650px !important;
        padding: 35px 45px !important;
    }
}

// DESKTOP
@media screen AND (min-width: 767px) AND (max-width: 1024px) {
    .portfolioContainer {
        background-color: #fff;
        border-radius: 15px;
        border-style: none;
        // margin-top: 15px;
        // width: 58%;
        width: 85% !important;
        max-width: 850px !important;
        padding: 35px 0 !important;
    }
}

// WIDE
@media screen AND (min-width: 1025px) {
    .portfolioContainer {
        background-color: #fff;
        border-radius: 15px;
        border-style: none;
        // margin-top: 15px;
        // width: 58%;
        width: 85%;
        max-width: 1015px !important;
        padding: 55px 65px !important;
    }
}